.title-arrow {
  font-style: normal;
}

.title-arrow.sort::after {
  content: '↕';
}

.title-arrow.sort-asc::after {
  content: '↑';
}

.title-arrow.sort-desc::after {
  content: '↓';
}
